<template>
  <ItemDetails
    width="620px"
    :value="value"
    :history="history"
    :overview="overview"
    @edit="handleEdit"
    @input="handleInput"
    @delete="handleDelete"
  >
    <template #title>Connection Details</template>

    <template #fields="{ value: fields }">
      <ol v-if="fields && fields.length">
        <li v-for="field in fields" :key="field.id">
          {{ field.name }}
        </li>
      </ol>

      <div v-else>-</div>
    </template>
  </ItemDetails>
</template>

<script>
import { integration } from "@/api/factory.js";
import ItemDetails from "@/components/common/display/item-details/ItemDetails.vue";

export default {
  name: "ConnectionDetails",

  components: { ItemDetails },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    columns: {
      type: Array,
      required: true,
    },

    entry: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isLoading: false,
      history: [],
    };
  },

  computed: {
    overview() {
      return this.columns.map((column) => ({
        id: column.id,
        name: column.name,
        label: column.label,
        value: this.entry[column.name],
      }));
    },
  },

  watch: {
    entry: {
      immediate: true,
      deep: true,
      handler() {
        if (this.entry.id) {
          this.getConnectionHistory();
        }
      },
    },
  },

  methods: {
    handleInput(value) {
      this.$emit("input", value);
    },

    handleEdit() {
      this.$emit("input", false);
      this.$emit("edit", this.entry.id);
    },

    handleDelete() {
      this.$emit("input", false);
      this.$emit("delete", this.entry.id);
    },

    /* api functions */

    async getConnectionHistory() {
      this.isLoading = true;

      const { error, payload } = await integration.getIntegrationHistory(
        this.entry.id
      );

      this.isLoading = false;

      if (error) {
        this.$alert.error(error);
        return;
      }

      this.history = payload;
    },

    /* ... */
  },
};
</script>

<style lang="scss" scoped>
ol {
  padding-left: 16px;
  margin: 0px;

  li {
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 0px;
    }
  }
}
</style>
