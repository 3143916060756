<template>
  <Sheet
    :value="value"
    width="30vw"
    height="70vh"
    has-footer
    @input="closeSheet"
  >
    <!-- title -->

    <template #title>
      {{ channel.id ? "Edit Channel" : "Add Channel" }}</template
    >

    <!-- ... -->

    <template #default>
      <div class="q-pa-md">
        <ValidationObserver ref="form">
          <div class="field-wrapper">
            <ValidationProvider
              v-slot="{ errors }"
              name="name"
              :rules="{ required: true }"
            >
              <TextField
                v-model="name"
                label="name"
                is-mandatory
                :error="errors[0]"
                class="q-mb-md"
              />
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors }"
              name="Channel Name"
              :rules="{ required: true }"
            >
              <SelectField
                v-model="channelId"
                label="Channel Name"
                is-mandatory
                :options="channelList"
                :error="errors[0]"
                class="q-mb-md"
              />
            </ValidationProvider>
          </div>
        </ValidationObserver>
      </div>
    </template>

    <template #footer>
      <BaseButton
        :label="channel.id ? 'Update' : 'Add'"
        color="primary"
        @click="addChannel"
      />
    </template>
  </Sheet>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Sheet from "@/components/common/popup/Sheet.vue";
import TextField from "@/components/common/form/text-field/TextField.vue";
import { connector } from "@/api/factory.js";
import SelectField from "@/components/common/form/select-field/SelectField.vue";

export default {
  name: "AddChannelInSlack",

  components: {
    ValidationObserver,
    ValidationProvider,
    Sheet,
    TextField,
    SelectField,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    channel: {
      type: Object,
      default: () => {},
    },

    connectorId: {
      type: [String, Number],
      required: true,
    },

    channelList: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      name: "",
      channelId: "",
    };
  },

  watch: {
    channel: {
      immediate: true,
      handler() {
        this.reset();
        if (this.channel.id) {
          // let channel = this.channelList.find(
          //   (name) => name.label === this.channel.channelName
          // );
          this.name = this.channel.name;
          this.channelId = this.channel.channelId;
        }
      },
    },
  },

  methods: {
    closeSheet() {
      this.$emit("close");
    },

    reset() {
      this.name = "";
      this.channelId = "";
    },

    async addChannel() {
      const valid = await this.$refs.form.validate();

      if (!valid) {
        return;
      }

      this.$store.commit("showLoadingBar");

      let channel = this.channelList.find(
        (item) => item.value === this.channelId
      );

      if (this.channel.id) {
        let input = {
          name: this.name,
          connectorId: this.connectorId,
          connectorType: "slack",
          hubPoint: this.channelId,
          inDataPoint: JSON.stringify({
            channelId: this.channelId,
            channelName: channel.label,
          }),
        };
        const { error } = await connector.updateLookUp(this.channel.id, input);

        this.$store.commit("hideLoadingBar");

        if (error) {
          this.$alert.error(error);
          return;
        }
      } else {
        let input = {
          name: this.name,
          connectorId: this.connectorId,
          channelName: channel.label,
          connectorType: "slack",
          channelId: this.channelId,
        };
        const { error } = await connector.addChannelInSlack(input);
        this.$store.commit("hideLoadingBar");

        if (error) {
          this.$alert.error(error);
          return;
        }
      }
      if (this.channel.id) {
        this.$alert.success(`channel edited successfully`);
      } else {
        this.$alert.success(`Channel saved successfully`);
      }
      this.closeSheet();
      this.$emit("save");
    },
  },
};
</script>
<style lang="scss">
#query-result {
  table {
    width: 100%;
    border-collapse: collapse;
    white-space: nowrap;
    margin-bottom: 15px;
    // margin-right: 10px;

    tr {
      height: 36px;

      th {
        color: var(--secondary);
        text-align: left;
      }
    }

    th,
    td {
      border: 1px solid var(--divider-color);
      padding: 8px;
      font-weight: 500;
    }
  }
}

#single-choice-field.singleChoice {
  .options-wrapper {
    border-top: none !important;
    padding-top: 0px !important;
  }
}
</style>
