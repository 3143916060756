<template>
  <ItemList
    :columns="columns"
    :items="items"
    :mode="mode"
    @select="handleSelect"
    @edit="handleEdit"
    @delete="handleDelete"
    @restore="handleRestore"
    @more="handleMore"
    @info="handleInfo"
  >
    <template #entries="{ value, item }">
      <div class="stat" @click.stop="$emit('entries', item.id)">
        <div class="ellipsis">
          {{ value || "-" }}
        </div>
      </div>
    </template>
  </ItemList>
</template>

<script>
import ItemList from "@/components/common/display/ItemList.vue";

export default {
  name: "ConnectionEntryList",

  components: { ItemList },

  props: {
    columns: {
      type: Array,
      required: true,
    },

    items: {
      type: Array,
      required: true,
    },

    mode: {
      type: String,
      required: true,
    },
  },

  methods: {
    handleSelect(formEntryId) {
      this.$emit("select", formEntryId);
    },

    handleEdit(formEntryId) {
      this.$emit("edit", formEntryId);
    },

    handleDelete(formEntryId) {
      this.$emit("delete", formEntryId);
    },

    handleRestore(formEntryId) {
      this.$emit("restore", formEntryId);
    },

    handleMore(formEntryId) {
      this.$emit("more", formEntryId);
    },

    handleInfo(formEntryId) {
      this.$emit("info", formEntryId);
    },
  },
};
</script>

<style lang="scss" scoped></style>
