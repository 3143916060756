<template>
  <AppLayout>
    <ViewWrapper
      title="Connection"
      tab="slack-browse"
      :breadcrumbs="breadcrumbs"
      @quickHelp="showQuickHelp"
    >
      <!-- header -->

      <template #header-actions>
        <BaseButton
          icon="eva-plus"
          label="add channel"
          class="q-ml-md"
          @click="addSlackChannel"
        />
      </template>

      <!-- ... -->

      <!-- quick help -->

      <QuickHelp v-model="quickHelp" />

      <!-- ... -->

      <div id="integration-builder-browse">
        <Portal to="action-bar">
          <ItemActions
            item="connection entry"
            :columns.sync="columns"
            :group-by.sync="groupBy"
            :order.sync="sortBy.order"
            :filter-by.sync="filterBy"
            :active-view.sync="activeView"
            :criteria.sync="sortBy.criteria"
            class="q-mb-sm"
            @refresh="getChannels"
            @search="searchRow"
          />
        </Portal>

        <div class="content">
          <ItemActionChips
            :sort-by="sortBy"
            :columns="columns"
            :group-by="groupBy"
            :filter-by="filterBy"
            @clearSortBy="clearSortBy"
            @clearGroupBy="clearGroupBy"
          />

          <ConnectionEntryGrid
            v-if="activeView === 'GRID'"
            :mode="mode"
            :items="items"
            :columns="columns"
            @edit="editLookup"
            @more="viewConnectionEntry"
            @delete="showDeleteConfirmation"
            @restore="showRestoreConfirmation"
          />

          <ConnectionEntryList
            v-if="activeView === 'LIST'"
            :mode="mode"
            :items="items"
            :columns="columns"
            @edit="editLookup"
            @more="viewConnectionEntry"
            @delete="showDeleteConfirmation"
            @restore="showRestoreConfirmation"
          />
        </div>

        <Pagination
          :total-items="totalItems"
          :current-page.sync="currentPage"
          :items-per-page.sync="itemsPerPage"
        />

        <ConnectionDetails
          v-if="isConnectionDetailsVisible"
          v-model="isConnectionDetailsVisible"
          :columns="columns"
          :entry="selectedLookup"
          @edit="editLookup"
          @delete="showDeleteConfirmation"
        />

        <ConfirmDeleteConnectionEntry
          v-model="isDeleteConfirmationVisible"
          :app-name="selectedLookup?.name"
          @delete="updateLookup"
        />

        <ConfirmRestoreConnectionEntry
          v-model="isRestoreConfirmationVisible"
          :app-name="selectedLookup?.name"
          @restore="updateLookup"
        />

        <AddChannelInSlack
          :value="addChannelSheet"
          :connector-id="id"
          :channel="selectedLookup"
          :channel-list="channelList"
          @close="
            {
              selectedLookup = {};
              addChannelSheet = false;
            }
          "
          @save="getChannels"
        ></AddChannelInSlack>
      </div>
    </ViewWrapper>
  </AppLayout>
</template>

<script>
import AppLayout from "@/layouts/app/AppLayout.vue";
import ViewWrapper from "@/components/common/view-wrapper/ViewWrapper.vue";
import QuickHelp from "@/components/common/QuickHelp.vue";
import { connector } from "@/api/factory.js";
import DataType from "@/constants/data-type.js";
import { Portal } from "portal-vue";
import Pagination from "@/components/common/display/Pagination.vue";
import ItemActions from "@/components/common/display/item-actions/ItemActions.vue";
import ItemActionChips from "@/components/common/display/ItemActionChips.vue";
import ConnectionEntryGrid from "../ConnectionEntryGrid.vue";
import ConnectionEntryList from "../ConnectionEntryList.vue";
import ConfirmDeleteConnectionEntry from "../ConfirmDeleteConnectionEntry.vue";
import ConfirmRestoreConnectionEntry from "../ConfirmRestoreConnectionEntry.vue";
import ConnectionDetails from "../ConnectionDetails.vue";
import AddChannelInSlack from "./AddChannelInSlack.vue";

export default {
  name: "SlackBrowse",

  components: {
    AppLayout,
    ViewWrapper,
    QuickHelp,
    Portal,
    ItemActionChips,
    ItemActions,
    ConnectionEntryGrid,
    ConnectionEntryList,
    Pagination,
    ConfirmDeleteConnectionEntry,
    ConfirmRestoreConnectionEntry,
    ConnectionDetails,
    AddChannelInSlack,
  },

  props: {
    mode: {
      type: String,
      default: "",
    },

    id: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      breadcrumbs: [
        {
          id: this.$nano.id(),
          label: "dashboard",
          route: "dashboard",
        },
        {
          id: this.$nano.id(),
          label: "settings",
          route: "settings",
        },
        {
          id: this.$nano.id(),
          label: "integration",
          route: "integration-browse",
        },
        {
          id: this.$nano.id(),
          label: "slack",
          route: "integration-builder",
          query: "app:slack",
        },
        {
          id: this.$nano.id(),
          label: "browse",
          route: "",
        },
      ],
      quickHelp: false,
      columns: [
        {
          id: this.$nano.id(),
          name: "name",
          label: "Name",
          type: "TITLE",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "channelName",
          label: "Channel Name",
          type: "SUBTITLE",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SHORT_TEXT,
        },

        {
          id: this.$nano.id(),
          name: "createdBy",
          label: "Created By",
          type: "NORMAL",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "createdAt",
          label: "Created At",
          type: "NORMAL",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.DATE_TIME,
        },
        {
          id: this.$nano.id(),
          name: "modifiedBy",
          label: "Modified By",
          type: "NORMAL",
          isVisible: true,
          isSortable: true,
          isGroupable: true,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: this.$nano.id(),
          name: "modifiedAt",
          label: "Modified At",
          type: "NORMAL",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
          dataType: DataType.DATE_TIME,
        },
      ],
      activeView: "LIST",
      isDeleteConfirmationVisible: false,
      isRestoreConfirmationVisible: false,
      sortBy: {
        criteria: "",
        order: "DESC",
      },
      groupBy: "",
      filterBy: [],
      totalItems: 0,
      itemsPerPage: 50,
      currentPage: 1,
      lookups: [],
      lookupsData: [],
      selectedLookup: {},
      jiraConnectSheet: false,
      isConnectionDetailsVisible: false,
      smtpConnectSheet: false,
      sqlConnectSheet: false,
      sqlConnectionId: 0,
      addChannelSheet: false,
      channelList: [],
    };
  },

  computed: {
    items() {
      let rows = [];
      rows = this.lookups.map(({ key, value }) => ({
        key: this.groupBy,
        value: key,
        data: value.map((entry) => ({
          id: entry.id,
          name: entry.name,
          channelName: JSON.parse(entry.inDataPoint).channelName,
          channelId: entry.hubPoint,
          connectorId: entry.connectorId,
          createdBy: entry.createdByEmail,
          createdAt: this.$day.format(entry.createdAt),
          modifiedBy: entry.modifiedByEmail,
          modifiedAt: this.$day.format(entry.modifiedAt),
          icon: "mdi-slack",
        })),
      }));
      return rows;
    },
  },

  mounted() {
    this.getChannelList();
    this.$watch(
      (vm) => [
        vm.mode,
        vm.sortBy,
        vm.groupBy,
        vm.filterBy,
        vm.currentPage,
        vm.itemsPerPage,
      ],
      () => this.getChannels(),
      { deep: true, immediate: true }
    );
  },

  created() {
    // this.getChannelList();
  },

  methods: {
    showQuickHelp() {
      this.quickHelp = true;
    },

    clearSortBy() {
      this.sortBy = {
        criteria: "",
        order: "DESC",
      };
    },

    clearGroupBy() {
      this.groupBy = "";
    },

    showDeleteConfirmation(lookupId) {
      this.selectedLookup = this.findEntry(lookupId);
      this.isDeleteConfirmationVisible = true;
    },

    showRestoreConfirmation(lookupId) {
      this.selectedLookup = this.findEntry(lookupId);
      this.isRestoreConfirmationVisible = true;
    },

    findEntry(lookupId) {
      const entries = [];
      this.items.forEach((item) => entries.push(...item.data));
      return entries.find((entry) => entry.id === lookupId);
    },

    addSlackChannel() {
      this.selectedLookup = {};
      this.addChannelSheet = true;
    },

    editLookup(lookupId) {
      this.selectedLookup = this.findEntry(lookupId);
      this.addChannelSheet = true;
    },

    viewConnectionEntry(lookupId) {
      this.selectedLookup = this.findEntry(lookupId);
      this.isConnectionDetailsVisible = true;
    },

    searchRow(search) {
      if (search) {
        this.lookups = this.lookupsData.map(({ key, value }) => ({
          key: key,
          value: value.filter((row) => {
            for (let cell in row) {
              let index = this.columns.findIndex((column) => {
                return column.name === cell;
              });
              if (index >= 0 && row[cell]) {
                if (
                  String(row[cell]).toLowerCase().includes(search.toLowerCase())
                ) {
                  return row;
                }
              }
            }
          }),
        }));
      } else {
        this.lookups = this.lookupsData;
      }
      this.totalItems = this.lookups[0].value.length;
    },

    async updateLookup(payload) {
      this.$store.commit("showLoadingBar");
      const { error } = await connector.updateLookUp(
        this.selectedLookup.id,
        payload
      );

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      this.$alert.success(`channel deleted`);
      this.getChannels();
    },

    async getChannels() {
      this.$store.commit("showLoadingBar");

      const { error, payload } = await connector.getLookUp({
        filterBy: [
          {
            filters: [
              {
                criteria: "createdBy",
                condition: "IS_EQUALS_TO",
                value: this.$store.state.session.id,
                // dataType: "SINGLE_SELECT",
              },
              {
                criteria: "connectorId",
                condition: "IS_EQUALS_TO",
                value: Number(this.id),
                // dataType: "SINGLE_SELECT",
              },
            ],
            groupCondition: "",
          },
        ],
        mode: this.mode,
      });
      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      let result = [];
      payload.forEach((item) => {
        result.push(item.connectorHub);
      });
      const result1 = result.filter(
        (item) =>
          item.createdBy === this.$store.state.session.id &&
          item.connectorId === Number(this.id)
      );

      let data = [
        {
          key: "",
          value: result1,
        },
      ];
      this.lookups = data || [];
      this.lookupsData = data || [];
      //this.totalItems = meta?.totalItems || 0;
      this.totalItems = result1.length || 0;
    },

    async getChannelList() {
      this.channelList = [];
      this.$store.commit("showLoadingBar");

      const { error, payload } = await connector.getChannelList({
        connectorId: this.id,
        listType: "",
        teamId: "",
      });
      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.Channels.length) {
        payload.Channels.forEach((item) => {
          this.channelList.push({
            id: this.$nano.id(),
            label: item.ChannelName,
            value: item.ChannelID,
          });
        });
      }
    },

    getChannelName(id) {
      let channelName = this.channelList.find((name) => name.value === id);
      if (channelName) {
        return channelName.label;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
#integration-builder-browse {
  .content {
    padding: 16px 0px;
    min-height: calc(100vh - 256px);
  }
}
</style>
